import { createSlice } from "@reduxjs/toolkit";
import { getPrivacyPolicyAction } from "./privacyPolicyThunk";

const initialState = {
    data: {
        privacyPolicy: {}
    },

    privacyPolicyData: {
        data: null,
        loading: false,
        error: null
    }
};

const privacyPolicySlice = createSlice({
    name: 'privacyPolicySlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //  GET PrivacyPolicyAction Action
            .addCase(getPrivacyPolicyAction.pending, (state = initialState) => {
                state.privacyPolicyData.loading = true;
                state.privacyPolicyData.error = null;
                state.privacyPolicyData.data = null;
            })

            .addCase(getPrivacyPolicyAction.fulfilled, (state = initialState, action) => {
                state.privacyPolicyData.loading = false;
                state.privacyPolicyData.error = null;
                state.privacyPolicyData.data = action.payload;
                state.data.privacyPolicy = action.payload?.pP;
            })

            .addCase(getPrivacyPolicyAction.rejected, (state = initialState, action) => {
                state.privacyPolicyData.loading = false;
                state.privacyPolicyData.error = action.payload;
            })
    },
});

export default privacyPolicySlice.reducer;