import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPrivacyPolicy } from "../../api/lib/termsAndPrivacyPolicy.api";

export const getPrivacyPolicyAction = createAsyncThunk(
    'privacyPolicySlice/getPrivacyPolicyAction',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getPrivacyPolicy(payload);

            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
)