import { apiClient } from '../apiClient';

/**
 * The function `getUserAccounts` is an asynchronous function that makes a GET request to retrieve all
 * CDS accounts from an API.
 * @returns The `getUserAccounts` function is returning the result of the `apiClient.get` method call.
 */
export const getUserAccounts = async () => {
  try {
    return await apiClient.get('/api/v1/cds-account/get-all-cds-accounts');
  } catch (error) {
    throw error;
  }
};

/**
 * The function `getUserProfileDetials` makes an asynchronous API call to retrieve user profile
 * information.
 * @returns The function `getUserProfileDetials` is returning the result of the `apiClient.get` method
 * call.
 */
export const getUserProfileDetials = async () => {
  try {
    return await apiClient.get('/api/v1/users/profile/info');
  } catch (error) {
    throw error;
  }
};

/**
 * The function `getUserBillingDetails` makes an asynchronous API call to retrieve user profile billing
 * information.
 * @returns The function `getUserBillingDetails` is returning the result of the `apiClient.get` method
 * call.
 */
export const getUserBillingDetails = async () => {
  try {
    return await apiClient.get(`/api/v1/billing/findUserBillingDetails`);
  } catch (error) {
    throw error;
  }
};

/**
 * This function retrieves the account summary information for a specific account ID using an API call.
 * @param accounId - The `accounId` parameter in the `getUserAccountSummary` function is used to
 * specify the account number for which the account summary information is being requested.
 * @returns The `getUserAccountSummary` function is returning the result of the API call made to fetch
 * account information for the specified account number.
 */
export const getUserAccountSummary = async (accounId) => {
  try {
    return await apiClient.get(`/api/v1/users/dashboard/accountInfo?accountNumber=${accounId}`);
  } catch (error) {
    throw error;
  }
};

export const getUserPortfolioStatus = async (accountNumber) => {
  try {
    return  await apiClient.get(`/api/v1/users/portfolio?accountNumber=${accountNumber}`);
  } catch (error) {
    throw error;
  }
};
