// src/redux/slices/newsSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { getNewsAction } from './newsThunk';

const initialState = {
  news: {
    data: {
      newsItems: [],
    },
    loading: false,
    error: null,
  },
};

const newsSlice = createSlice({
  name: 'newsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNewsAction.pending, (state) => {
        state.news.loading = true;
        state.news.error = null;
      })
      .addCase(getNewsAction.fulfilled, (state, action) => {
        state.news.loading = false;
        state.news.data = action.payload;
      })
      .addCase(getNewsAction.rejected, (state, action) => {
        state.news.loading = false;
        state.news.error = action.payload;
      });
  },
});

export default newsSlice.reducer;
