import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyDetails } from '../../api/lib/companyDetails.api';

export const companyDetailsAction = createAsyncThunk(
  'companyDetailsSlice/getCompanyDetails',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCompanyDetails();

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
