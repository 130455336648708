import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import './FormTimer.css';

const FormTimerProps = {
  // label text
  label: '',
  // end time stamp
  time: 0,
  // expire callback
  onExpire: null,
};

// method to get digit string
const getDigitString = (input) => {
  // convert to string
  input = input.toString();
  // return double digit string
  return `${input.length === 1 ? '0' : ''}${input}`;
};

// method to get time text
const getTimeString = (input) => {
  // return zero if less than zero
  if (input <= 0) {
    return '00:00';
  }
  // get minutes value
  const min = Math.floor(input > 60 ? input / 60 : 0);
  // remove minutes from input
  input = input - min * 60;
  // get seconds value
  const sec = input;
  // return time string
  return `${getDigitString(min)}:${getDigitString(sec)}`;
};

const FormTimer = (props = FormTimerProps) => {
  // get current time
  const current = useSelector((state) => state.states.timer.stamp);
  // last reset value
  const [last, setLast] = useState(null);
  // calculate remain time
  const remain = parseInt((props.time - current) / 1000);
  // update last on expire
  if (remain <= 0 && last !== props.time) {
    setLast(props.time);
  }
  // effect on last change
  useEffect(() => {
    // callback if changed
    if (props.onExpire && remain <= 0) {
      props.onExpire(remain);
    }
  }, [remain]);
  // return timer dom
  return (
    <div className='form-element-timer typo-body-v2'>
      <div className='form-element-timer-label'>{props.label || ''}</div>
      <div className='form-element-timer-value' data-expired={remain <= 0}>
        {getTimeString(remain)}
      </div>
    </div>
  );
};

export default FormTimer;
