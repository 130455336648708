import { apiClient } from "../apiClient"

/**
 * The function `getTermsAndConditions` makes an asynchronous API call to retrieve all terms and conditons data 
 * from and API
 * @returns The `getTermsAndConditions` function is returning the result of the `apiClient.get` method call.
 */
export const getTermsAndConditions = async ({ type, category }) => {
    try {
        return await apiClient.get(`/api/v1/terms-n-conditions-privacy-policy/terms-n-conditions-privacy-policy`, {
            params: {
                type: type,
                category: category
            },
        });
    } catch (error) {
        throw error;
    }
}

/**
 * The function `getPrivacyPolicy` makes an asynchronous API call to retrieve all privacy policy data 
 * from and API
 * @returns The `getPrivacyPolicy` function is returning the result of the `apiClient.get` method call.
 */
export const getPrivacyPolicy = async ({ type, category }) => {
    try {
        return await apiClient.get('/api/v1/terms-n-conditions-privacy-policy/terms-n-conditions-privacy-policy', {
            params: {
                type: type,
                category: category
            },
        });
    } catch (error) {
        throw error;
    }
}