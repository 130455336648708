import { createSlice } from '@reduxjs/toolkit';
import {
  forgetPasswordValidateNICAction,
  forgetPasswordVerifyEmailAction,
  forgetPasswordValidateOTPForCDSAction,
  forgetPasswordValidateEmailAction,
  forgetPasswordValidateCDSAccountAction,
  resetPasswordAction,
  validatePasswordAction,
  changePasswordAction,
  forgetPasswordEmailValidateAccount,
  forgetPasswordValidateOtp,
  forgetPasswordResendOtp,
  resetExpiredPasswordAction
} from './passwordThunks';

const initialState = {
  currentPassword: ``,
  isPasswordChangedSuccess: false,
  validateOldPassword: {
    isOldPasswordConfirmed: false,
    noOfPendingAttempts: 4,
    isPasswordIncorrect: false,
    isAccountLocked: false,
    isLastAttempt: false,
  },
  user: {
    isCDSAccountExists: false,
    otpDuration: 0,
    userId: '',
    email: '',
    mobile: '',
    isOTPValid: false,
    password: '',
  },
  validateNIC: {
    loading: false,
    error: null,
  },
  cdsAccountValidation: {
    loading: false,
    error: null,
  },
  emailValidation: {
    loading: false,
    message: '',
    error: null,
  },
  emailOTPValidation: {
    loading: false,
    message: '',
    error: null,
  },
  cdsOTPValidation: {
    loading: false,
    error: null,
  },
  forgotPassword: {
    loading: false,
    data: null,
    error: null,
  },
  validatePassword: {
    loading: false,
    data: null,
    error: null,
  },
  changePassword: {
    loading: false,
    data: null,
    error: null,
  },
  resetExpiredPassword: {
    loading: false,
    data: null,
    error: null,
  },
};

const passwordSlice = createSlice({
  name: 'passwordSlice',
  initialState,
  reducers: {
    setPasswordData: (state = initialState, action) => {
      state.user = {
        ...state.user,
        [action.payload.key]: action.payload.value,
      };
    },
    resetPasswordStateData: () => initialState,
    setCurrentPassword: (state, action) => {
      state.currentPassword = action.payload;
    },
    resetUserAndEmail: (state) => {
      state.user = initialState.user;
      state.emailValidation = initialState.emailValidation
    },
    resetOldPasswordConfirmation: (state) => {
      state.validateOldPassword.isOldPasswordConfirmed = false;
      state.validateOldPassword.noOfPendingAttempts = 4;
      state.validateOldPassword.isPasswordIncorrect = false;
      state.validateOldPassword.isAccountLocked = false;
    },
    setOtpDuration: (state) => {
      state.user.otpDuration = 89;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgetPasswordValidateNICAction.pending, (state = initialState) => {
        state.validateNIC.loading = true;
        state.validateNIC.error = null;
      })
      .addCase(forgetPasswordValidateNICAction.fulfilled, (state = initialState, action) => {
        state.validateNIC.loading = false;
        state.user.isCDSAccountExists = action.payload['cdsExists'];
        state.user.userId = action.payload['userId'];
      })
      .addCase(forgetPasswordValidateNICAction.rejected, (state = initialState, action) => {
        state.validateNIC.loading = false;
        state.validateNIC.error = action.payload;
      })
      .addCase(forgetPasswordValidateCDSAccountAction.pending, (state = initialState) => {
        state.cdsAccountValidation.loading = true;
        state.cdsAccountValidation.error = null;
      })
      .addCase(forgetPasswordValidateCDSAccountAction.fulfilled, (state = initialState, action) => {
        state.cdsAccountValidation.loading = false;
        state.user.otpDuration = action.payload['otpDuration'];
        state.user.email = action.payload['email'];
        state.user.mobile = action.payload['mobile'];
      })
      .addCase(forgetPasswordValidateCDSAccountAction.rejected, (state = initialState, action) => {
        state.cdsAccountValidation.loading = false;
        state.cdsAccountValidation.error = action.payload;
      })
      .addCase(forgetPasswordEmailValidateAccount.pending, (state = initialState) => {
        state.emailValidation.loading = true;
        state.emailValidation.error = null;
      })
      .addCase(forgetPasswordEmailValidateAccount.fulfilled, (state = initialState, action) => {
        state.emailValidation.loading = false;
        state.user.otpDuration = action.payload['otpStatus'] ? 89 : undefined;
        state.emailValidation.message = action.payload['message'];
      })
      .addCase(forgetPasswordEmailValidateAccount.rejected, (state = initialState, action) => {
        state.emailValidation.loading = false;
        state.emailValidation.error = action.payload;
      })
      // resend OTP
      .addCase(forgetPasswordResendOtp.pending, (state = initialState) => {
        state.emailValidation.loading = true;
        state.emailValidation.error = null;
      })
      .addCase(forgetPasswordResendOtp.fulfilled, (state = initialState, action) => {
        state.emailValidation.loading = false;
        state.user.otpDuration = action.payload['otpStatus'] ? 89 : undefined;
        state.emailValidation.message = action.payload['message'];
      })
      .addCase(forgetPasswordResendOtp.rejected, (state = initialState, action) => {
        state.emailValidation.loading = false;
        state.emailValidation.error = action.payload;
      })
      // forgetPasswordValidateOTPForCDSAction
      .addCase(forgetPasswordValidateOTPForCDSAction.pending, (state = initialState) => {
        state.cdsOTPValidation.loading = true;
        state.cdsOTPValidation.error = null;
      })
      .addCase(forgetPasswordValidateOTPForCDSAction.fulfilled, (state = initialState, action) => {
        state.cdsOTPValidation.loading = false;
        if (action.payload['initials'] && action.payload['nameWithInitials']) {
          state.user.isOTPValid = true;
        }
      })
      .addCase(forgetPasswordValidateOTPForCDSAction.rejected, (state = initialState, action) => {
        state.cdsOTPValidation.loading = false;
        state.cdsOTPValidation.error = action.payload;
      })
      // forget Password Validate OTP
      .addCase(forgetPasswordValidateOtp.pending, (state = initialState) => {
        state.emailOTPValidation.loading = true;
        state.emailOTPValidation.error = null;
      })
      .addCase(forgetPasswordValidateOtp.fulfilled, (state = initialState, action) => {
        state.emailOTPValidation.loading = false;
        if (action.payload['message'] === 'Valid OTP') {
          state.user.isOTPValid = true;
        }
      })
      .addCase(forgetPasswordValidateOtp.rejected, (state = initialState, action) => {
        state.emailOTPValidation.loading = false;
        state.emailOTPValidation.error = action.payload;
      })
      // resetPasswordAction
      .addCase(resetPasswordAction.pending, (state = initialState) => {
        state.forgotPassword.loading = true;
        state.forgotPassword.error = null;
      })
      .addCase(resetPasswordAction.fulfilled, (state = initialState, action) => {
        state.forgotPassword.loading = false;
        state.forgotPassword.data = action.payload;
      })
      .addCase(resetPasswordAction.rejected, (state = initialState, action) => {
        state.forgotPassword.loading = false;
        state.forgotPassword.error = action.payload;
      })
      // validatePasswordAction
      .addCase(validatePasswordAction.pending, (state = initialState) => {
        state.validatePassword.loading = true;
        state.validatePassword.error = null;
      })
      .addCase(validatePasswordAction.fulfilled, (state = initialState, action) => {
        state.validatePassword.loading = false;
        state.validatePassword.data = action.payload;
        if (action.payload.code === `R1001`) {
          state.validateOldPassword.isOldPasswordConfirmed = true;
        }
      })
      .addCase(validatePasswordAction.rejected, (state = initialState, action) => {
        state.validatePassword.loading = false;
        state.validatePassword.error = action.payload;
        if (action.payload.status === `BAD_REQUEST`) {
          state.validateOldPassword.isPasswordIncorrect = true;
          state.validateOldPassword.noOfPendingAttempts -= 1;
          if (state.validateOldPassword.noOfPendingAttempts === 1) {
            state.validateOldPassword.isLastAttempt = true;
          }
        }
        if (action.payload.status === `LOCKED`) {
          state.validateOldPassword.isAccountLocked = true;
        }
      })
      // changePasswordAction
      .addCase(changePasswordAction.pending, (state = initialState) => {
        state.changePassword.loading = true;
        state.changePassword.error = null;
      })
      .addCase(changePasswordAction.fulfilled, (state = initialState, action) => {
        state.changePassword.loading = false;
        state.changePassword.data = action.payload;
        state.isPasswordChangedSuccess = true
      })
      .addCase(changePasswordAction.rejected, (state = initialState, action) => {
        state.changePassword.loading = false;
        state.changePassword.error = action.payload;
      })
      // resetExpiredPasswordAction
      .addCase(resetExpiredPasswordAction.pending, (state = initialState) => {
        state.resetExpiredPassword.loading = true;
        state.resetExpiredPassword.error = null;
      })
      .addCase(resetExpiredPasswordAction.fulfilled, (state = initialState, action) => {
        state.resetExpiredPassword.loading = false;
        state.resetExpiredPassword.data = action.payload;
      })
      .addCase(resetExpiredPasswordAction.rejected, (state = initialState, action) => {
        state.resetExpiredPassword.loading = false;
        state.resetExpiredPassword.error = action.payload;
      });
  },
});

export const { setPasswordData, resetPasswordStateData, setCurrentPassword, resetUserAndEmail, resetOldPasswordConfirmation, setOtpDuration } =
  passwordSlice.actions;
export default passwordSlice.reducer;
