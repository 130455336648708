// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  -webkit-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-family: 'Poppins';
}

html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}

body {
  margin: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAiB;UAAjB,iBAAiB;EACjB,wCAAwC;EACxC,sBAAsB;AACxB;;AAEA;EACE,8BAA8B;EAC9B,0BAA0B;AAC5B;;AAEA;EACE,WAAW;AACb","sourcesContent":["* {\n  user-select: none;\n  -webkit-tap-highlight-color: transparent;\n  font-family: 'Poppins';\n}\n\nhtml {\n  -webkit-text-size-adjust: none;\n  touch-action: manipulation;\n}\n\nbody {\n  margin: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
