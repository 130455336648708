import { createSlice } from "@reduxjs/toolkit";
import { getTVChartAction } from "./tv-chart-configThunk";

const initialState = {
    data: {
        tvChartConfigs: {}
    },

    tvChartConfigsData: {
        data: null,
        loading: false,
        error: null
    }
};

const tvChartSlice = createSlice({
    name: 'privacyPolicySlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //  GET PrivacyPolicyAction Action
            .addCase(getTVChartAction.pending, (state = initialState) => {
                state.tvChartConfigsData.loading = true;
                state.tvChartConfigsData.error = null;
                state.tvChartConfigsData.data = null;
            })

            .addCase(getTVChartAction.fulfilled, (state = initialState, action) => {
                state.tvChartConfigsData.loading = false;
                state.tvChartConfigsData.error = null;
                state.tvChartConfigsData.data = action.payload;
                state.data.privacyPolicy = action.payload?.pP;
            })

            .addCase(getTVChartAction.rejected, (state = initialState, action) => {
                state.tvChartConfigsData.loading = false;
                state.tvChartConfigsData.error = action.payload;
            })
    },
});

export default tvChartSlice.reducer;