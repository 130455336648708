import { apiClient } from '../apiClient';
import { getSession } from '../../helpers/helpers';

export const postValidateCDSAccount = async (payload) => {
  try {
    return await apiClient.post(
      'api/v1/users/validate/CDS',
      {
        cdsAccountNumber: payload.cdsAccountNumber,
      },
      {
        headers: {
          'user-id': getSession('userId'),
        },
      },
    );
  } catch (error) {
    throw error;
  }
};

export const postValidateNIC = async (payload) => {
  try {
    return await apiClient.post('api/v1/users/validate', {
      idType: payload.idType,
      idNumber: payload.idNumber,
    });
  } catch (error) {
    throw error;
  }
};
export const postValidateForgotPasswordNIC = async (payload) => {
  try {
    return await apiClient.post('api/v1/users/forget-password/validate', {
      idType: payload.idType,
      idNumber: payload.idNumber,
    });
  } catch (error) {
    throw error;
  }
};

/**
 * The function `postSendOTP` sends a POST request to a specified API endpoint with the
 * provided payload and user ID header for new user.
 * @param payload - The `payload` parameter is an object that contains two properties: {type,value} type need to be `EMAIL` or `PHONE`
 * @returns the result of the `apiClient.post` method call.
 */
export const postSendOTP = async (payload) => {
  try {
    return await apiClient.post(
      '/api/v1/users/otp/send',
      {
        type: payload.type,
        value: payload.value,
      },
      {
        headers: {
          'user-id': getSession('userId'),
        },
      },
    );
  } catch (error) {
    throw error;
  }
};

export const validateAccount = async (payload) => {
  try {
    return await apiClient.post(
      '/api/v1/users/forget-password/validateAccount',
      {
        type: payload.type,
        value: payload.value,
      },
      {
        headers: {
          'user-id': getSession('userId'),
        },
      },
    );
  } catch (error) {
    throw error;
  }
};

export const validateOtp = async (payload) => {
  try {
    return await apiClient.post(
      '/api/v1/users/forget-password/validateOtp',
      {
        // type: payload.type,
        otp: payload.otp,
      },
      {
        headers: {
          'user-id': getSession('userId'),
        },
      },
    );
  } catch (error) {
    throw error;
  }
};

export const resendOtp = async (payload) => {
  try {
    return await apiClient.post(
      '/api/v1/users/forget-password/resendOtp',
      {
        type: payload.type,
      },
      {
        headers: {
          'user-id': getSession('userId'),
        },
      },
    );
  } catch (error) {
    throw error;
  }
};

/**
 * The function `postVerifyOTP` is an asynchronous function that sends a POST request to verify an
 * email OTP (One-Time Password) with the provided payload for new user.
 * @param payload - The `payload` parameter is an object that contains the following properties: {type, otp} type need to be `EMAIL` or `PHONE`
 * @returns the result of the `apiClient.post` method call.
 */
export const postVerifyOTP = async (payload) => {
  try {
    return await apiClient.post(
      '/api/v1/users/otp/verify',
      {
        type: payload.type,
        otp: payload.otp,
      },
      {
        headers: {
          'user-id': getSession('userId'),
        },
      },
    );
  } catch (error) {
    throw error;
  }
};

/**
 * The function `postCreateUser` is an asynchronous function that sends a POST request to create a new
 * user with the given payload data.
 * @param payload - The `payload` parameter is an object that contains the following properties:
 * @returns the result of the `apiClient.post` method call.
 */
export const postCreateUser = async (payload) => {
  try {
    return await apiClient.post('/api/v1/users/create-user', {
      createUserType: payload.createUserType,
      userId: payload.userId,
      title: payload.title,
      nameWithInitials: payload.nameWithInitials,
      lastName: payload.lastName,
      password: payload.password,
      isTAndCAccepted: false,
      isPPAccepted: false,
    });
  } catch (error) {
    throw error;
  }
};

export const getReferrerDetails = async (referrerCode) => {
  try {
    return await apiClient.get(`api/v1/referral/user/getReferrerDetails/${referrerCode}`);
  } catch (error) {
    throw error;
  }
};

export const getUsernameAvailability = async (username) => {
  try {
    return await apiClient.get('/api/v1/users/username/availability', {
      params: {
        username: username
      }
    });
  } catch (error) {
    throw error;
  }
};