import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTermsAndConditions } from "../../api/lib/termsAndPrivacyPolicy.api"

export const getTermsAndConditionsAction = createAsyncThunk(
    'termsAndConditionSlice/getTermsAndConditionsAction',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getTermsAndConditions(payload);

            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);