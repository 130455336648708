// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-element-timer {
  display: flex;
  gap: 8px;
}

.form-element-timer-label {
  font-weight: 500;
}

.form-element-timer-value {
  font-weight: 700;
  color: var(--primary-color-a);
}

.form-element-timer-value[data-expired='true'] {
  color: var(--semantic-error-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/form/timer/FormTimer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".form-element-timer {\n  display: flex;\n  gap: 8px;\n}\n\n.form-element-timer-label {\n  font-weight: 500;\n}\n\n.form-element-timer-value {\n  font-weight: 700;\n  color: var(--primary-color-a);\n}\n\n.form-element-timer-value[data-expired='true'] {\n  color: var(--semantic-error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
