import { createAsyncThunk } from '@reduxjs/toolkit';
import { postLoginUser } from '../../api/lib/auth.api';

export const loginAction = createAsyncThunk(
  'loginSlice/loginAction',
  async (payload = { userName: '', password: '' }, { rejectWithValue }) => {
    try {
      return await postLoginUser(payload.userName, payload.password);
    } catch (error) {
      console.log('here', error);
      return rejectWithValue(error.response.data);
    }
  },
);


