import { createSlice } from '@reduxjs/toolkit';
import { getAllFAQAction, getFAQByCategoryAction } from './faqThunk';

const initialState = {
  allFAQ: {
    data: [],
    loading: false,
    error: null,
  },
  FAQByCategory: {
    data: [],
    loading: false,
    error: null,
  },
};

const faqSlice = createSlice({
  name: 'faqSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllFAQAction.pending, (state = initialState) => {
        state.allFAQ.loading = true;
        state.allFAQ.error = null;
        state.allFAQ.data = null;
      })
      .addCase(getAllFAQAction.fulfilled, (state = initialState, action) => {
        state.allFAQ.loading = false;
        state.allFAQ.error = null;
        state.allFAQ.data = action.payload;
      })
      .addCase(getAllFAQAction.rejected, (state = initialState, action) => {
        state.allFAQ.loading = false;
        state.allFAQ.error = action.payload;
      })

      .addCase(getFAQByCategoryAction.pending, (state = initialState) => {
        state.FAQByCategory.loading = true;
        state.FAQByCategory.error = null;
        state.FAQByCategory.data = null;
      })
      .addCase(getFAQByCategoryAction.fulfilled, (state = initialState, action) => {
        state.FAQByCategory.loading = false;
        state.FAQByCategory.error = null;
        state.FAQByCategory.data = action.payload;
      })
      .addCase(getFAQByCategoryAction.rejected, (state = initialState, action) => {
        state.FAQByCategory.loading = false;
        state.FAQByCategory.error = action.payload;
      });
  },
});

export default faqSlice.reducer;
