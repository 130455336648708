import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllFAQ, getFAQByCategory } from '../../api/lib/faq.api';

export const getAllFAQAction = createAsyncThunk(
  'faqSlice/getAllFAQAction',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllFAQ();

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const getFAQByCategoryAction = createAsyncThunk(
  'faqSlice/getFAQByCategoryAction',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getFAQByCategory(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
