import React, { lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flow, Step } from '../../constants/enums';
import { setCurrentFlow } from '../../store/flowSlice';
const SignUp = lazy(() => import('./signup/SignUp'));
const CDSVerify = lazy(() => import('./cds-verify/CDSVerify'));
const CDSVerifyOTP = lazy(() => import('./cds-verify-otp/CDSVerifyOTP'));
const EmailVerify = lazy(() => import('./email-verify/EmailVerify'));
const EmailVerifyOTP = lazy(() => import('./email-verify-otp/EmailVerifyOTP'));
const MobileVerify = lazy(() => import('./mobile-verify/MobileVerify'));
const MobileVerifyOTP = lazy(() => import('./mobile-verify-otp/MobileVerifyOTP'));
const Register = lazy(() => import('./register/Register'));
const Password = lazy(() => import('./password/Password'));

const OnBoardingPage = () => {
  const dispatch = useDispatch();
  const flowData = useSelector((state) => state.flowSlice.flowData);
  let componentToRender = null;

  useEffect(() => {
    dispatch(setCurrentFlow({ flow: Flow.ONBOARDING, step: Step.SIGNUP }))
  }, [])

  if (flowData.current.flow === Flow.ONBOARDING) {
    switch (flowData.current.step) {
      case Step.SIGNUP:
        componentToRender = <SignUp />;
        break;
      case Step.MOBILE_VERIFY:
        componentToRender = <MobileVerify />;
        break;
      case Step.EMAIL_VERIFY:
        componentToRender = <EmailVerify />;
        break;
      case Step.MOBILE_VERIFY_OTP:
        componentToRender = <MobileVerifyOTP />;
        break;
      case Step.EMAIL_VERIFY_OTP:
        componentToRender = <EmailVerifyOTP />;
        break;
      case Step.CDS_VERIFY:
        componentToRender = <CDSVerify />;
        break;
      case Step.CDS_VERIFY_OTP:
        componentToRender = <CDSVerifyOTP />;
        break;
      case Step.REGISTER:
        componentToRender = <Register />;
        break;
      case Step.PASSWORD:
        componentToRender = <Password />;
        break;
      default:
        break;
    }
  }

  return <React.Fragment>{componentToRender}</React.Fragment>;
};

export default OnBoardingPage;
