// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/common/logo.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/common/logo-large.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/onboarding/slide-1.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../assets/onboarding/slide-2.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../assets/onboarding/slide-3.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../assets/onboarding/slide-4.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../assets/onboarding/slide-5.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../assets/onboarding/slide-6.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("../assets/onboarding/slide-7.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("../assets/erros/404.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("../assets/erros/503.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* common */

.image-logo {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.image-logo-large {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

/* onboarding-layout */

.image-onboarding-slide-1 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

.image-onboarding-slide-2 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

.image-onboarding-slide-3 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}

.image-onboarding-slide-4 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}

.image-onboarding-slide-5 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}

.image-onboarding-slide-6 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
}

.image-onboarding-slide-7 {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_8___});
}

/* error pages */

.error-page-container[data-type='not_found'] {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_9___});
}

.error-page-container[data-type='under_maintainance'] {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_10___});
}
`, "",{"version":3,"sources":["webpack://./src/styles/images.css"],"names":[],"mappings":"AAAA,WAAW;;AAEX;EACE,yDAAgD;AAClD;;AAEA;EACE,yDAAsD;AACxD;;AAEA,sBAAsB;;AAEtB;EACE,yDAAuD;AACzD;;AAEA;EACE,yDAAuD;AACzD;;AAEA;EACE,yDAAuD;AACzD;;AAEA;EACE,yDAAuD;AACzD;;AAEA;EACE,yDAAuD;AACzD;;AAEA;EACE,yDAAuD;AACzD;;AAEA;EACE,yDAAuD;AACzD;;AAEA,gBAAgB;;AAEhB;EACE,yDAA8C;AAChD;;AAEA;EACE,0DAA8C;AAChD","sourcesContent":["/* common */\n\n.image-logo {\n  background-image: url(../assets/common/logo.svg);\n}\n\n.image-logo-large {\n  background-image: url(../assets/common/logo-large.svg);\n}\n\n/* onboarding-layout */\n\n.image-onboarding-slide-1 {\n  background-image: url(../assets/onboarding/slide-1.png);\n}\n\n.image-onboarding-slide-2 {\n  background-image: url(../assets/onboarding/slide-2.png);\n}\n\n.image-onboarding-slide-3 {\n  background-image: url(../assets/onboarding/slide-3.png);\n}\n\n.image-onboarding-slide-4 {\n  background-image: url(../assets/onboarding/slide-4.png);\n}\n\n.image-onboarding-slide-5 {\n  background-image: url(../assets/onboarding/slide-5.png);\n}\n\n.image-onboarding-slide-6 {\n  background-image: url(../assets/onboarding/slide-6.png);\n}\n\n.image-onboarding-slide-7 {\n  background-image: url(../assets/onboarding/slide-7.png);\n}\n\n/* error pages */\n\n.error-page-container[data-type='not_found'] {\n  background-image: url(../assets/erros/404.svg);\n}\n\n.error-page-container[data-type='under_maintainance'] {\n  background-image: url(../assets/erros/503.svg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
