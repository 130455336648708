import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUserAccountSummary,
  getUserAccounts,
  getUserBillingDetails,
  getUserPortfolioStatus,
  getUserProfileDetials,
} from '../../api/lib/userProfile.api';
import { uploadProfilePic } from '../../api/lib/uploadProfilePic.api';
import { getNoticeData, postAcceptNoticeData } from '../../api/lib/auth.api';
import { getUserActiveReferralDetails } from '../../api/lib/referral.api';
import { setLocal } from '../../../src/helpers/helpers';

export const getAllUserAccounts = createAsyncThunk(
  'userProfileSlice/getUserAccounts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUserAccounts();

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getUserProfileDetails = createAsyncThunk(
  'userProfileSlice/getUserProfileDetails',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUserProfileDetials();
      setLocal('cdsAccountNumber', response?.data?.cdsAccountNumber);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const uploadUserProfilePic = createAsyncThunk(
  'userProfileSlice/uploadUserProfilePic',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await uploadProfilePic(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getNoticeDataAction = createAsyncThunk(
  'userProfileSlice/getNoticeDataAction',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getNoticeData(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const getReferralDetails = createAsyncThunk(
  'userProfileSlice/getReferralDetails',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getUserActiveReferralDetails(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const postAcceptNoticeDataAction = createAsyncThunk(
  'userProfileSlice/postAcceptNoticeDataAction',
  async (payload = {}, { rejectWithValue }) => {
    try {
      const response = await postAcceptNoticeData(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getUserBillingDetailsAction = createAsyncThunk(
  'userProfileSlice/getUserBillingDetailsAction',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUserBillingDetails();

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const getUserAccountSummaryAction = createAsyncThunk(
  'userProfileSlice/getUserAccountSummaryAction',
  async (accounId, { rejectWithValue }) => {
    try {
      const response = await getUserAccountSummary(accounId);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getUserPortfolioStatusAction = createAsyncThunk(
  'userProfileSlice/getUserPortfolioStatusAction',
  async (accountNumber, { rejectWithValue }) => {
    try {
      const response = await getUserPortfolioStatus(accountNumber);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
