// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-popup-outer {
  z-index: 10000;
  position: fixed;
  left: 0px;
  top: 0px;
  opacity: 0;
  transition: opacity 0.2s !important;
}

.react-popup-backdrop {
  background-color: #0006;
  position: absolute;
  z-index: 0;
  width: 100vw;
  height: 100vh;
}

.react-popup-container {
  position: absolute;
  z-index: 1;
  overflow: hidden;
  margin-top: -20px;
  transition: margin-top 0.15s ease-out !important;
  left: 50vw;
  top: 50vh;
  transform: translateX(-50%) translateY(-50%);
  width: 450px;
  height: 300px;
  box-shadow: 0px 5px 12px #0002;
}

@keyframes popup-close {
  0% {
    width: 100vw;
    height: 100vh;
  }
  99% {
    width: 100vw;
    height: 100vh;
  }
  100% {
    overflow: hidden;
    width: 0px;
    height: 0px;
  }
}

.react-popup-outer[data-state='hidden'] {
  display: none;
}

.react-popup-outer[data-state='closed'] {
  animation: popup-close 0.2s ease 1 forwards;
}

.react-popup-outer[data-state='opened'] {
  opacity: 1;
}

.react-popup-outer[data-state='opened'] .react-popup-container {
  margin-top: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/modules/PopUp.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,SAAS;EACT,QAAQ;EACR,UAAU;EACV,mCAAmC;AACrC;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,gBAAgB;EAChB,iBAAiB;EACjB,gDAAgD;EAChD,UAAU;EACV,SAAS;EACT,4CAA4C;EAC5C,YAAY;EACZ,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE;IACE,YAAY;IACZ,aAAa;EACf;EACA;IACE,YAAY;IACZ,aAAa;EACf;EACA;IACE,gBAAgB;IAChB,UAAU;IACV,WAAW;EACb;AACF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".react-popup-outer {\n  z-index: 10000;\n  position: fixed;\n  left: 0px;\n  top: 0px;\n  opacity: 0;\n  transition: opacity 0.2s !important;\n}\n\n.react-popup-backdrop {\n  background-color: #0006;\n  position: absolute;\n  z-index: 0;\n  width: 100vw;\n  height: 100vh;\n}\n\n.react-popup-container {\n  position: absolute;\n  z-index: 1;\n  overflow: hidden;\n  margin-top: -20px;\n  transition: margin-top 0.15s ease-out !important;\n  left: 50vw;\n  top: 50vh;\n  transform: translateX(-50%) translateY(-50%);\n  width: 450px;\n  height: 300px;\n  box-shadow: 0px 5px 12px #0002;\n}\n\n@keyframes popup-close {\n  0% {\n    width: 100vw;\n    height: 100vh;\n  }\n  99% {\n    width: 100vw;\n    height: 100vh;\n  }\n  100% {\n    overflow: hidden;\n    width: 0px;\n    height: 0px;\n  }\n}\n\n.react-popup-outer[data-state='hidden'] {\n  display: none;\n}\n\n.react-popup-outer[data-state='closed'] {\n  animation: popup-close 0.2s ease 1 forwards;\n}\n\n.react-popup-outer[data-state='opened'] {\n  opacity: 1;\n}\n\n.react-popup-outer[data-state='opened'] .react-popup-container {\n  margin-top: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
