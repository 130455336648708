import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllSubscriptionData,
  getSubscriptionComparison,
  getChangeSubscriptionRequest,
  postFreeTierUserSubscription,
  postUserSubscription,
  postUserUnsubscibeSubscription,
} from '../../api/lib/subscription.api';

export const getAllSubscriptionAction = createAsyncThunk(
  'subscriptionSlice/getSubscriptionData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllSubscriptionData();

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const subscriptionsComparisonAction = createAsyncThunk(
  'subscriptionSlice/getSubscriptionComparisonData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSubscriptionComparison();

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const subscriptionChangeRequestAction = createAsyncThunk(
  'subscriptionSlice/subscriptionChangeRequestAction',
  async (payload = { subscriptionId: '', renewalPeriod: `` }, { rejectWithValue }) => {
    try {
      return await getChangeSubscriptionRequest(payload.subscriptionId, payload.renewalPeriod);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const subscriptionUnsubscibeAction = createAsyncThunk(
  'subscriptionSlice/subscriptionUnsubscibeAction',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await postUserUnsubscibeSubscription(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
