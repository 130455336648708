// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-element-row {
  display: flex;
  gap: 24px;
}

.form-element-row .form-element-text-input {
  flex-grow: 1;
}

.form-element-row.align-center {
  justify-content: center;
}

.form-element-row.align-left {
  justify-content: left;
}

.form-element-row.align-right {
  justify-content: right;
}

@media (max-width: 570px) {
  .form-element-row {
    flex-direction: column;
    gap: 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/form/raw/FormRaw.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,sBAAsB;IACtB,QAAQ;EACV;AACF","sourcesContent":[".form-element-row {\n  display: flex;\n  gap: 24px;\n}\n\n.form-element-row .form-element-text-input {\n  flex-grow: 1;\n}\n\n.form-element-row.align-center {\n  justify-content: center;\n}\n\n.form-element-row.align-left {\n  justify-content: left;\n}\n\n.form-element-row.align-right {\n  justify-content: right;\n}\n\n@media (max-width: 570px) {\n  .form-element-row {\n    flex-direction: column;\n    gap: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
