// src/redux/thunks/newsThunk.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNews } from '../../api/lib/news.api';

export const getNewsAction = createAsyncThunk(
  'newsSlice/getNewsAction',
  async (symbol, { rejectWithValue }) => {
    try {
      const response = await getNews(symbol);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  },
);
