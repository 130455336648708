import { apiClient } from '../apiClient';

export const getAllFAQ = async () => {
  try {
    return await apiClient.get('/api/v1/faq/findAllFAQ');
  } catch (error) {
    throw error;
  }
};

/**
 * The function `getFAQByCategory` is an asynchronous function that makes a GET request to the
 * `/api/v1/faq/getFAQbyCategory` endpoint with a specified category parameter and returns the
 * response.
 * @param category - The `category` parameter is used to specify the category of the frequently asked
 * questions (FAQs) that you want to retrieve. It is a string value that represents the category of the
 * FAQs.
 * @returns the result of the `apiClient.get` method, which is a Promise.
 */
export const getFAQByCategory = async ({category}) => {
  try {
    return await apiClient.get(`/api/v1/faq/getFAQbyCategory`, {
      params: {
        category: category
      },
    });
  } catch (error) {
    throw error;
  }
};
