import './FormRaw.css';

const FormRawProps = {
  // given class name
  className: '',
  // item align
  align: 'center',
};

const FormRaw = (props = FormRawProps) => {
  // get default props
  const className = props.className || '';
  const align = props.align || 'center';
  // return dom
  return <div className={`form-element-row align-${align} ${className}`}>{props.children}</div>;
};

export default FormRaw;
