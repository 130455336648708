import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getIsUserAuthenticated } from './helpers/utils';

const ProtectedRoutes = () => {
  const isValidUser = getIsUserAuthenticated();

  if (process.env.REACT_APP_NODE_ENV === 'local') {
    return <Outlet />;
  }

  if (isValidUser) {
    return <Outlet />;
  } else {
    return <Navigate to={'/login'} />;
  }
};

export default ProtectedRoutes;
