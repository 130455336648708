import { apiClient } from '../apiClient';

/**
 * The function `getAllSubscriptionData` makes an asynchronous API call to retrieve all active
 * subscriptions.
 * @returns The function `getAllSubscriptionData` is returning the result of the `apiClient.get` method
 * call, which is a Promise.
 */
export const getAllSubscriptionData = async () => {
  try {
    return await apiClient.get('/api/v1/subscription/findAllActiveSubscriptions');
  } catch (error) {
    throw error;
  }
};

/**
 * The function `getSubscriptionComparison` makes an asynchronous API call to retrieve subscription
 * features.
 * @returns the result of the `apiClient.get('/api/v1/subscription/features')` call.
 */
export const getSubscriptionComparison = async () => {
  try {
    return await apiClient.get('/api/v1/subscription/features');
  } catch (error) {
    throw error;
  }
};

export const getChangeSubscriptionRequest = async (subscriptionId, renewalPeriod) => {
  try {
    return await apiClient.get(
      `/api/v1/users/subscription/findSubscriptionDetails/${subscriptionId}?renewalPeriod=${renewalPeriod}`,
    );
  } catch (error) {
    throw error;
  }
};

export const postFreeTierUserSubscription = async (payload) => {
  try {
    return await apiClient.post('/api/v1/users/subscription/saveUserSubscription', payload);
  } catch (error) {
    throw error;
  }
};

export const postUserSubscription = async (payload) => {
  try {
    return await apiClient.post('/api/v1/users/subscription/saveUserSubscription', payload);
  } catch (error) {
    throw error;
  }
};

export const postUserUnsubscibeSubscription = async (payload) => {
  try {
    return await apiClient.post('/api/v1/users/subscription/cancelUserSubscription', payload);
  } catch (error) {
    throw error;
  }
};
