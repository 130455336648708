import axios from 'axios';
import { getSession, setSession } from '../helpers/helpers';
import { getGuestToken } from './lib/auth.api';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_USER_SVC_HOST,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(async (config) => {
  let authToken = getSession('auth_token');

  if (!authToken) {
    authToken = await getGuestToken();
    setSession('auth_token', authToken);
  }

  const decodedToken = JSON.parse(atob(authToken.split('.')[1]));

  if (decodedToken.exp) {
    const tokenExpireDateTime = new Date(decodedToken.exp * 1000);
    const currentDateTime = new Date();

    if (currentDateTime < tokenExpireDateTime) {
      config.headers.Authorization = `Bearer ${authToken}`;
    } else {
      authToken = await getGuestToken();
      setSession('auth_token', authToken);
      config.headers.Authorization = `Bearer ${authToken}`;
    }
  }

  return config;
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export { apiClient };
