import { useEffect, lazy, Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import axios from 'axios';
import { setData, setTimerData } from './store/common/commonSlice';
import { getLocal, setLocal, setSession } from './helpers/helpers';

import ProtectedRoutes from './ProtectedRoutes';

import './styles/common.css';
import './styles/icons.css';
import './styles/images.css';
import './styles/themes.css';
import './styles/typography.css';
import OnBoardingPage from './pages/onboarding';
import TVChartPage from './pages/TVChart/TVChart';
import { getSession } from './helpers/helpers';
import FormPopUp from './components/form/popup/FormPopUp';
import FormHeading from './components/form/heading/FormHeading';
import FormRaw from './components/form/raw/FormRaw';
import FormButton from './components/form/button/FormButton';
import FormTimer from './components/form/timer/FormTimer';
import { JWTDecoder, logoutAndRedirect } from './helpers/utils';
import { setFifteenMinsStatus } from './store/login/loginSlice';
import { getAccessToken } from './api/lib/auth.api';

const Alert = lazy(() => import('./components/alert/Alert'));
const Ribbon = lazy(() => import('./components/ribbon/Ribbon'));
const Home = lazy(() => import('./pages/home/Home'));
const Refer = lazy(() => import('./pages/refer/Refer'));
const Pricing = lazy(() => import('./pages/pricing/Pricing'));
const Checkout = lazy(() => import('./pages/pricing/checkout/Checkout'));
const Checkout1 = lazy(() => import('./pages/pricing/checkout/Checkout1'));
// const PGW = lazy(() => import('./pages/pricing/pgw/PGW'));
// const PGW = lazy(() => import('./pages/pricing/pgw/PGW'));
const Login = lazy(() => import('./pages/login/Login'));
const Help = lazy(() => import('./pages/help/Help'));
const ForgetPassword = lazy(() => import('./pages/password'));
const PrivacyPolicy = lazy(() => import('./pages/privacy-policy/PrivacyPolicy'));
const PasswordExpiration = lazy(() => import('./pages/password-expiration/PasswordExpiration'));
const TermsAndConditions = lazy(() => import('./pages/terms-and-conditions/TermsAndConditions'));
const Error = lazy(() => import('./pages/error/Error'));
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const ExamplePopUpForm = lazy(() => import('./pages/examples/popup-form/ExamplePopUpForm'));
const ExampleSlideForm = lazy(() => import('./pages/examples/side-form/ExampleSlideForm'));
const ExamplePricingPage = lazy(() => import('./pages/examples/pricing-page/ExamplePricingPage'));
const ProfileAccounts = lazy(() =>
  import('./components/dashboard/profile/accounts/ProfileAccounts'),
);
const ProfileBilling = lazy(() => import('./components/dashboard/profile/billing/ProfileBilling'));
const ProfileDetails = lazy(() => import('./components/dashboard/profile/details/ProfileDetails'));
const ProfileMember = lazy(() => import('./components/dashboard/profile/member/ProfileMember'));
const ProfileNotification = lazy(() =>
  import('./components/dashboard/profile/notification/ProfileNotification'),
);
const ProfileSettings = lazy(() =>
  import('./components/dashboard/profile/settings/ProfileSettings'),
);
const ProfileReferral = lazy(() => import('./components/dashboard/profile/Referral/Referral'));
const ChangePasswordPopups = lazy(() => import('./components/password/ChangePasswordPopups'));

const Timer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Version Number - 1.0.5');
    const tick = setInterval(() => {
      dispatch(setTimerData());
    }, 1000);
    return () => clearInterval(tick);
  }, [dispatch]);

  return <></>;
};

const TokenExpiryHandler = (props) => {
  const authToken = getSession('auth_token');
  const dispatch = useDispatch();
  const [time] = useState(Date.now());
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (authToken) {
        const checkCondition = () => {
          setShowModal(true);
          dispatch(setFifteenMinsStatus(true));
          return;
        };
        const intervalId = setInterval(() => {
          let lastActivityTime = getLocal('lastActvity');
          if (!((new Date().getTime() - new Date(lastActivityTime).getTime()) / (1000 * 60) < 14)) {
            clearInterval(intervalId);
            checkCondition();
          }
        }, 1000);
      }
    };
    checkTokenExpiry();
  }, [authToken]);

  const handleOnExpire = async (remain) => {
    setShowModal(false);
    dispatch(setFifteenMinsStatus(false));
    await logoutAndRedirect();
  };

  const handleOnClose = () => {
    props.onClose();
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <FormPopUp open={true}>
          <FormHeading
            size='medium'
            align='center'
            title='You will be logged out soon!'
            description="Hello! It seems you've been inactive for a while. You will be automatically logged out in 1 minute. If you're still using the system, please click 'Stay Logged In' below"
          />
          <FormTimer time={Date.now() + 59000} onExpire={handleOnExpire} />
          <FormRaw className='expire-password-popup-login-button'>
            <FormButton width='223px' onClick={handleOnClose}>
              Stay Logged In
            </FormButton>
          </FormRaw>
        </FormPopUp>
      )}
    </>
  );
};

const Globals = () => (
  <>
    <Ribbon />
    <Alert />
  </>
);

const RouteList = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path='/examples'>
            <Route
              element={
                <>
                  <Globals />
                  <ExamplePopUpForm />
                </>
              }
              path='popup-form'
            />
            <Route
              element={
                <>
                  <Globals />
                  <ExampleSlideForm />
                </>
              }
              path='slide-form'
            />
            <Route
              element={
                <>
                  <Globals />
                  <ExamplePricingPage />
                </>
              }
              path='pricing-page'
            />
            <Route
              element={
                <>
                  <Globals />
                  <ProfileAccounts />
                </>
              }
              path='profile-accounts'
            />
            <Route
              element={
                <>
                  <Globals />
                  <ProfileBilling />
                </>
              }
              path='profile-billing'
            />
            <Route
              element={
                <>
                  <Globals />
                  <ProfileDetails />
                </>
              }
              path='profile-details'
            />
            <Route
              element={
                <>
                  <Globals />
                  <ProfileMember />
                </>
              }
              path='profile-member'
            />
            <Route
              element={
                <>
                  <Globals />
                  <ProfileNotification />
                </>
              }
              path='profile-notification'
            />
            <Route
              element={
                <>
                  <Globals />
                  <ProfileSettings />
                </>
              }
              path='profile-settings'
            />
            <Route
              element={
                <>
                  <Globals />
                  <ProfileReferral />
                </>
              }
              path='profile-referral'
            />
            <Route
              element={
                <>
                  <Globals />
                  <ChangePasswordPopups />
                </>
              }
              path='change-password'
            />
          </Route>
        </Route>
        <Route
          element={
            <>
              <Globals />
              <Home />
            </>
          }
          path='/'
        />
        <Route path='/pricing'>
          <Route
            element={
              <>
                <Globals />
                <Pricing />
              </>
            }
            index
          />
          <Route element={<ProtectedRoutes />}>
            <Route
              element={
                <>
                  <Globals />
                  <Checkout />
                </>
              }
              path='checkout'
            />
            <Route
              element={
                <>
                  <Globals />
                  <Checkout1 />
                </>
              }
              path='checkout1'
            />
            {/* <Route
              element={
                <>
                  <Globals />
                  <PGW />
                </>
              }
              path='pgw'
            /> */}
          </Route>
        </Route>
        <Route
          element={
            <>
              <Globals />
              <Login />
            </>
          }
          path='login'
        />
        <Route
          element={
            <>
              <Globals />
              <Refer />
            </>
          }
          path='refer'
        />
        <Route
          element={
            <>
              <Globals />
              <OnBoardingPage />
            </>
          }
          path='signup'
        />
        <Route
          path='forget-password'
          element={
            <>
              <Globals />
              <ForgetPassword />
            </>
          }
        />
        <Route
          path='password-expiration'
          element={
            <>
              <Globals />
              <PasswordExpiration />
            </>
          }
        />
        <Route
          element={
            <>
              <Globals />
              <PrivacyPolicy />
            </>
          }
          path='/privacy-policy'
        />
        <Route
          element={
            <>
              <Globals />
              <Help />
            </>
          }
          path='/help/:faqCategory'
        />
        <Route
          element={
            <>
              <Globals />
              <TermsAndConditions />
            </>
          }
          path='/terms-and-conditions'
        />
        <Route element={<ProtectedRoutes />}>
          <Route
            element={
              <>
                <Globals />
                <Dashboard />
              </>
            }
            path='/dashboard'
          />
        </Route>
        <Route element={<Error type='not_found' link='/' />} path='*' />
        <Route
          element={
            <>
              <Globals />
              <TVChartPage />
            </>
          }
          path='/tv-chart'
        />
      </Routes>
    </Suspense>
  );
};

const App = () => {
  const theme = useSelector((state) => state.states.theme);
  const dispatch = useDispatch();
  const [showLogOffModal, setShowModal] = useState(false);
  const loginSlice = useSelector((state) => state.loginSlice);
  useEffect(() => {
    axios.get('/data/update-notice.json').then((resp) => {
      dispatch(setData(resp.data));
    });
    axios.get('/data/plans.json').then((resp) => {
      dispatch(setData({ plans: resp.data }));
    });
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('mousemove', () => {
      setLocal('lastActvity', new Date());
    });
    document.addEventListener('click', () => {
      setLocal('lastActvity', new Date());
    });
    return () => {
      // document.removeEventListener('mousemove');
      // document.removeEventListener('click');
    };
  }, []);

  useEffect(() => {
    if (loginSlice.login.isFifteenMinsToExpire === false) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  }, [loginSlice]);

  const handleOnClose = async () => {
    dispatch(setFifteenMinsStatus(false));
    const resp = await getAccessToken();
    setSession('auth_token', resp?.data?.access_token);
    setSession('refresh_token', resp?.data?.refresh_token);
  };

  return (
    <div id='container' data-theme={theme}>
      <RouteList />
      <Timer />
      <TokenExpiryHandler open={showLogOffModal} onClose={handleOnClose} />
    </div>
  );
};

export default App;
