import { createAsyncThunk } from '@reduxjs/toolkit';
import { changePassword, putForgetPassword, resetExpiredPassword, validateCurrentPassword } from '../../api/lib/auth.api';
import {
  postSendOTP,
  postValidateCDSAccount,
  postValidateForgotPasswordNIC,
  postVerifyOTP,
  validateAccount,
  validateOtp,
  resendOtp
} from '../../api/lib/registeration.api';
import { postValidateOTPForCDS } from '../../api/lib/otp.api';

export const forgetPasswordValidateCDSAccountAction = createAsyncThunk(
  'registerSlice/forgetPasswordValidateCDSAccountAction',
  async (payload = { cdsAccountNumber: '' }, { rejectWithValue }) => {
    try {
      const response = await postValidateCDSAccount(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const forgetPasswordValidateNICAction = createAsyncThunk(
  'registerSlice/forgetPasswordValidateNICAction',
  async (payload = { idType: 'NIC', idNumber: '' }, { rejectWithValue }) => {
    try {
      const response = await postValidateForgotPasswordNIC(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const forgetPasswordVerifyEmailAction = createAsyncThunk(
  'registerSlice/forgetPasswordVerifyEmailAction',
  async (payload = { type: 'EMAIL', value: '' }, { rejectWithValue }) => {
    try {
      const response = await postSendOTP(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const forgetPasswordEmailValidateAccount = createAsyncThunk(
  'registerSlice/forgetPasswordEmailValidateAccount',
  async (payload = { type: 'EMAIL', value: '' }, { rejectWithValue }) => {
    try {
      const response = await validateAccount(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const forgetPasswordValidateOtp = createAsyncThunk(
  'registerSlice/forgetPasswordValidateEmailAction',
  async (payload = { otp: '' }, { rejectWithValue }) => {
    try {
      const response = await validateOtp(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const forgetPasswordResendOtp = createAsyncThunk(
  'registerSlice/forgetPasswordResendOtp',
  async (payload = { type: '' }, { rejectWithValue }) => {
    try {
      const response = await resendOtp(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);


export const forgetPasswordValidateOTPForCDSAction = createAsyncThunk(
  'registerSlice/forgetPasswordValidateOTPForCDSAction',
  async (payload = { otp: '' }, { rejectWithValue }) => {
    try {
      const response = await postValidateOTPForCDS(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const forgetPasswordValidateEmailAction = createAsyncThunk(
  'registerSlice/forgetPasswordValidateEmailAction',
  async (payload = { type: 'EMAIL', otp: '' }, { rejectWithValue }) => {
    try {
      const response = await postVerifyOTP(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const resetPasswordAction = createAsyncThunk(
  'passwordSlice/resetPasswordAction',
  async (payload = { password: '', userId: '' }, { rejectWithValue }) => {
    try {
      const response = await putForgetPassword(payload.password, payload.userId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const validatePasswordAction = createAsyncThunk(
  'passwordSlice/validatetPasswordAction',
  async (payload = { currentPassword: '' }, { rejectWithValue }) => {
    try {
      const response = await validateCurrentPassword(payload.currentPassword);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const changePasswordAction = createAsyncThunk(
  'passwordSlice/changePasswordAction',
  async (payload = { currentPassword: ``, newPassword: `` }, { rejectWithValue }) => {
    try {
      const response = await changePassword(payload.currentPassword, payload.newPassword);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const resetExpiredPasswordAction = createAsyncThunk(
  'passwordSlice/resetExpiredPasswordAction',
  async (payload = { username: '', currentPassword: '', newPassword: '' }, { rejectWithValue }) => {
    try {
      const response = await resetExpiredPassword(payload.username, payload.currentPassword, payload.newPassword);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);