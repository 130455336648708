import axios from 'axios';
import { apiClient } from '../apiClient';
import { getLocal, getSession } from '../../helpers/helpers';

export const getGuestToken = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_USER_SVC_HOST}/api/v1/users/guest-token`,
    );

    return response.data.access_token;
  } catch (error) {
    throw error;
  }
};

export const postLoginUser = async (userName, password) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_USER_SVC_HOST}/api/v1/users/login`,
      {
        username: userName,
        password: password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postLogoutUser = async () => {
  try {
    const refreshToken = sessionStorage.getItem('refresh_token');
    const refreshTokenWithoutQuotes = refreshToken.replace(/["']/g, '');
    if (!refreshToken) {
      throw new Error('Refresh token is null or undefined.');
    }
    return await apiClient.post(`/api/v1/users/logout?refreshToken=${refreshTokenWithoutQuotes}`);
  } catch (error) {
    throw error;
  }
};

export const getAccessToken = async () => {
  try {
    return await apiClient.get(
      `/api/v1/users/access-token?refreshToken=${getSession('refresh_token')}`,
    );
  } catch (error) {
    throw error;
  }
};

export const postUnlockUserRequest = async (userName) => {
  try {
    return await apiClient.post(`/api/v1/users/unlock?username=${userName}`);
  } catch (error) {
    throw error;
  }
};

export const putUnlockUser = async (otp, userName) => {
  try {
    return await apiClient.put(`/api/v1/users/unlock?otp=${otp}&username=${userName}`);
  } catch (error) {
    throw error;
  }
};

export const putForgetPassword = async (newPassword, userId) => {
  try {
    return await apiClient.post(
      `/api/v1/users/forget-password/resetPassword`,
      {
        password: newPassword,
      },
      {
        headers: {
          'user-id': userId,
        },
      },
    );
  } catch (error) {
    throw error;
  }
};

export const postCreateUser = async (payload) => {
  try {
    return await apiClient.post(`/api/v1/users/create-user`, payload);
  } catch (error) {
    throw error;
  }
};

export const getNoticeData = async ({ type, category }) => {
  try {
    return await apiClient.get(
      `/api/v1/terms-n-conditions-privacy-policy/terms-n-conditions-privacy-policy`,
      {
        params: {
          type: type,
          category: category,
        },
      },
    );
  } catch (error) {
    throw error;
  }
};

export const postAcceptNoticeData = async (payload) => {
  try {
    return await apiClient.post(
      `/api/v1/terms-n-conditions-privacy-policy/accept-terms-n-conditions-privacy-policy`,
      payload,
    );
  } catch (error) {
    throw error;
  }
};
// Change password flow apis

/**
 * The function `validateCurrentPassword` sends a POST request to the server to validate the current
 * password for a user's account.
 * @param currentPassword - The `currentPassword` parameter is the password that the user wants to
 * validate. It is passed to the `validateCurrentPassword` function as an argument.
 * @returns the result of the axios post request to the specified API endpoint.
 */
export const validateCurrentPassword = async (currentPassword) => {
  try {
    return await apiClient.post('api/v1/users/reset-password/validate', {
      currentPassword: currentPassword,
    });
  } catch (error) {
    throw error;
  }
};

/**
 * The `changePassword` function is an asynchronous function that sends a POST request to the
 * `api/v1/users/reset-password` endpoint with the current password and new password as parameters.
 * @param currentPassword - The current password of the user.
 * @param newPassword - The `newPassword` parameter is the new password that the user wants to set for
 * their account.
 * @returns The `resetPassword` function is returning a promise that resolves to the result of the
 * `axios.post` request.
 */
export const changePassword = async (currentPassword, newPassword) => {
  try {
    return await apiClient.post('api/v1/users/reset-password', {
      currentPassword: currentPassword,
      newPassword: newPassword,
    });
  } catch (error) {
    throw error;
  }
};

// Reset Expired Flow API
export const resetExpiredPassword = async (username, currentPassword, newPassword) => {
  try {
    return await apiClient.post('/api/v1/users/reset-expired-password', {
      username: username,
      currentPassword: currentPassword,
      newPassword: newPassword,
    });
  } catch (error) {
    throw error;
  }
};
