const Flow = Object.freeze({
  ONBOARDING: 'ONBOARDING',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
});

const Step = Object.freeze({
  SIGNUP: 'SIGNUP',
  MOBILE_VERIFY: 'ONBOARDING',
  EMAIL_VERIFY: 'EMAIL_VERIFY',
  MOBILE_VERIFY_OTP: 'MOBILE_VERIFY_OTP',
  EMAIL_VERIFY_OTP: 'EMAIL_VERIFY_OTP',
  CDS_VERIFY: 'CDS_VERIFY',
  CDS_VERIFY_OTP: 'CDS_VERIFY_OTP',
  REGISTER: 'REGISTER',
  PASSWORD: 'PASSWORD',
});

const ForgotPasswordSteps = Object.freeze({
  NIC_VERIFY: 'NIC_VERIFY',
  CDS_VERIFY: 'CDS_VERIFY',
  EMAIL_VERIFY: 'EMAIL_VERIFY',
  OTP_VERIFY: 'OTP_VERIFY',
  CREATE_NEW_PASSWORD: 'CREATE_NEW_PASSWORD',
});

const CreateUserType = Object.freeze({
  USER_EXISTING_CDS: `USER_EXISTING_CDS`,
  USER_NO_CDS: `USER_NO_CDS`,
});

export { Flow, Step, CreateUserType, ForgotPasswordSteps };
