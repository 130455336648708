// helper to read data from local storage
export const getLocal = (key, def = null) => {
  // check local storage
  if (key in localStorage) {
    // return local data
    return JSON.parse(localStorage.getItem(key));
  } else {
    // return default data
    return def;
  }
};

// helper to save data into local storage
export const setLocal = (key, data) => {
  // stringify data
  const text = JSON.stringify(data);
  // save data on local
  localStorage.setItem(key, text);
};

// helper to read data from session storage
export const getSession = (key, def = null) => {
  // check local storage
  if (key in sessionStorage) {
    // return local data
    return JSON.parse(sessionStorage.getItem(key));
  } else {
    // return default data
    return def;
  }
};

// helper to save data into session storage
export const setSession = (key, data) => {
  // stringify data
  const text = JSON.stringify(data);
  // save data on local
  sessionStorage.setItem(key, text);
};

// helper to await resolve
export const sleep = (time, callback) => {
  // return promise
  return new Promise((resolve) => {
    // timeout
    setTimeout(() => {
      // callback event
      if (callback) {
        callback();
      }
      // resolve
      resolve();
    }, time || 1000);
  });
};
