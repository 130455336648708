import { apiClient } from '../apiClient';

/**
 * The function `getUserActiveReferralDetails` is an asynchronous function that makes a GET request to retrieve all
 * CDS accounts from an API.
 * @returns The `getUserActiveReferralDetails` function is returning the result of the `apiClient.get` method call.
 */
export const getUserActiveReferralDetails = async () => {
    try {
        return await apiClient.get('/api/v1/referral/getUserActiveReferralDetails');
    } catch (error) {
        throw error;
    }
};