// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TVChartContainer {
	height: calc(100vh - 80px);
	/* background-color: brown; */
	/* margin-bottom: 10%; */
}
`, "",{"version":3,"sources":["webpack://./src/components/TVChartContainer/index.css"],"names":[],"mappings":"AAAA;CACC,0BAA0B;CAC1B,6BAA6B;CAC7B,wBAAwB;AACzB","sourcesContent":[".TVChartContainer {\n\theight: calc(100vh - 80px);\n\t/* background-color: brown; */\n\t/* margin-bottom: 10%; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
