import { apiClient } from '../apiClient';

/**
 * The function `uploadProfilePic` is an asynchronous function that makes a GET request to retrieve all
 * CDS accounts from an API.
 * @returns The `uploadProfilePic` function is returning the result of the `apiClient.get` method call.
 */
export const uploadProfilePic = async (imageFile) => {
    try {
        var bodyFormData = new FormData();
        bodyFormData.append('image', imageFile);

        return await apiClient.post('/api/v1/users/picture/upload', bodyFormData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    } catch (error) {
        throw error;
    }
};