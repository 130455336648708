import { createSlice } from '@reduxjs/toolkit';
import { getAllUserAccounts, getNoticeDataAction, getReferralDetails, getUserAccountSummaryAction, getUserBillingDetailsAction, getUserPortfolioStatusAction, getUserProfileDetails, postAcceptNoticeDataAction, uploadUserProfilePic } from './profileThunk';

const initialState = {
  userData: {
    profileInformation: {},
    accounts: {},
    profileBillingInformation: {},
  },

  userAccountsData: {
    data: null,
    loading: false,
    error: null,
  },
  userProfileData: {
    data: null,
    loading: false,
    error: null,
  },
  userProfilePic: {
    data: null,
    loading: false,
    error: null
  },
  noticeData: {
    loading: false,
    data: null,
    error: null,
  },
  agreedNoticeData: {
    loading: false,
    data: null,
    error: null,
  },
  referralDetails: {
    data: null,
    loading: false,
    error: null
  },
  userBillingDetails: {
    data: null,
    loading: false,
    error: null
  },
  userAccountSummary: {
    data: null,
    loading: false,
    error: null
  },
  userPortfolioStatus: {
    data: null,
    loading: false,
    error: null
  }
};

const userProfileSlice = createSlice({
  name: 'userProfileSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //   GET AllUserAccountData Action
      .addCase(getAllUserAccounts.pending, (state = initialState) => {
        state.userAccountsData.loading = true;
        state.userAccountsData.error = null;
        state.userAccountsData.data = null;
      })

      .addCase(getAllUserAccounts.fulfilled, (state = initialState, action) => {
        state.userAccountsData.loading = false;
        state.userAccountsData.error = null;
        state.userAccountsData.data = action.payload;
        state.userData.accounts = action.payload;
      })
      .addCase(getAllUserAccounts.rejected, (state = initialState, action) => {
        state.userAccountsData.loading = false;
        state.userAccountsData.error = action.payload;
      })
      //   GET AllUserAccountData Action
      .addCase(getUserProfileDetails.pending, (state = initialState) => {
        state.userProfileData.loading = true;
        state.userProfileData.error = null;
        state.userProfileData.data = null;
      })

      .addCase(getUserProfileDetails.fulfilled, (state = initialState, action) => {
        state.userProfileData.loading = false;
        state.userProfileData.error = null;
        state.userProfileData.data = action.payload;
        state.userData.profileInformation = action.payload;
        // mock user plan value - Silver subscription
        state.userProfileData.data.subscriptionId = action.payload?.subscription?.subscriptionId;
        state.userProfileData.data.subscriptionName = action.payload?.subscription?.subscriptionTitle;
      })
      .addCase(getUserProfileDetails.rejected, (state = initialState, action) => {
        state.userProfileData.loading = false;
        state.userProfileData.error = action.payload;
      })

      //   POST upload profile pic
      .addCase(uploadUserProfilePic.pending, (state = initialState) => {
        state.userProfilePic.loading = true;
        state.userProfilePic.error = null;
        state.userProfilePic.data = null;
      })

      .addCase(uploadUserProfilePic.fulfilled, (state = initialState, action) => {
        state.userProfilePic.loading = false;
        state.userProfilePic.error = null;
        state.userProfilePic.data = action.payload;
      })
      .addCase(uploadUserProfilePic.rejected, (state = initialState, action) => {
        state.userProfilePic.loading = false;
        state.userProfilePic.error = action.payload;
      })
      // GET Notice data action
      .addCase(getNoticeDataAction.pending, (state = initialState) => {
        state.noticeData.loading = true;
        state.noticeData.error = null;
      })
      .addCase(getNoticeDataAction.fulfilled, (state = initialState, action) => {
        state.noticeData.loading = false;
        state.noticeData.data = action.payload;
      })
      .addCase(getNoticeDataAction.rejected, (state = initialState, action) => {
        state.noticeData.loading = false;
        state.noticeData.error = action.payload;
      })
      // POST Agreed notice data action
      .addCase(postAcceptNoticeDataAction.pending, (state = initialState) => {
        state.agreedNoticeData.loading = true;
        state.agreedNoticeData.error = null;
      })
      .addCase(postAcceptNoticeDataAction.fulfilled, (state = initialState, action) => {
        state.agreedNoticeData.loading = false;
        state.agreedNoticeData.data = action.payload;
      })
      .addCase(postAcceptNoticeDataAction.rejected, (state = initialState, action) => {
        state.agreedNoticeData.loading = false;
        state.agreedNoticeData.error = action.payload;
      })

      //   Get referral details
      .addCase(getReferralDetails.pending, (state = initialState) => {
        state.referralDetails.loading = true;
        state.referralDetails.error = null;
        state.referralDetails.data = null;
      })

      .addCase(getReferralDetails.fulfilled, (state = initialState, action) => {
        state.referralDetails.loading = false;
        state.referralDetails.error = null;
        state.referralDetails.data = action.payload;
      })
      .addCase(getReferralDetails.rejected, (state = initialState, action) => {
        state.referralDetails.loading = false;
        state.referralDetails.error = action.payload;
      })
      //  GET user billing details
      .addCase(getUserBillingDetailsAction.pending, (state = initialState, action) => {
        state.userBillingDetails.loading = true;
        state.userBillingDetails.error = null;
        state.userBillingDetails.data = null;
      })
      .addCase(getUserBillingDetailsAction.fulfilled, (state = initialState, action) => {
        state.userBillingDetails.loading = false;
        state.userBillingDetails.error = null;
        state.userBillingDetails.data = action.payload;
        state.userData.profileBillingInformation = action.payload;
      })
      .addCase(getUserBillingDetailsAction.rejected, (state = initialState, action) => {
        state.userBillingDetails.loading = false;
        state.userBillingDetails.error = action.payload;
      })
      //  GET user acount summary
      .addCase(getUserAccountSummaryAction.pending, (state = initialState, action) => {
        state.userAccountSummary.loading = true;
        state.userAccountSummary.error = null;
        state.userAccountSummary.data = null;
      })
      .addCase(getUserAccountSummaryAction.fulfilled, (state = initialState, action) => {
        state.userAccountSummary.loading = false;
        state.userAccountSummary.error = null;
        state.userAccountSummary.data = action.payload;
      })
      .addCase(getUserAccountSummaryAction.rejected, (state = initialState, action) => {
        state.userAccountSummary.loading = false;
        state.userAccountSummary.error = action.payload;
      })
      // GET user portfolio status
      .addCase(getUserPortfolioStatusAction.pending, (state = initialState, action) => {
        state.userPortfolioStatus.loading = true;
        state.userPortfolioStatus.error = null;
        state.userPortfolioStatus.data = null;
      })
      .addCase(getUserPortfolioStatusAction.fulfilled, (state = initialState, action) => {
        state.userPortfolioStatus.loading = false;
        state.userPortfolioStatus.error = null;
        state.userPortfolioStatus.data = action.payload;
      })
      .addCase(getUserPortfolioStatusAction.rejected, (state = initialState, action) => {
        state.userPortfolioStatus.loading = false;
        state.userPortfolioStatus.error = action.payload;
      });
  },
});

export default userProfileSlice.reducer;
