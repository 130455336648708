import PopUp from '../../../modules/PopUp';
import './FormPopUp.css';

const FormPopUpProps = {
  // given class name
  className: '',
  // given backdrop class name
  backdropClassName: '',
  // popup opened state
  open: false,
  // popup icon (info | error | warning | sucess | locked | pending)
  icon: 'none',
  // input referer
  referer: null,
};

const icons = {
  info: 'danger-circle',
  error: 'danger-circle',
  warning: 'warning',
  sucess: 'correct-round',
  locked: 'lock',
  pending: 'pending',
};

const FormPopUp = (props = FormPopUpProps) => {
  // get default props
  const className = props.className || '';
  const backdropClassName = props.backdropClassName || '';
  const icon = props.icon || null;
  const referer = props.referer || null;
  // return popup dom
  return (
    <PopUp
      referer={referer}
      open={props.open}
      className={`form-element-popup ${className}`}
      backdropClassName={`form-element-popup-backdrop ${backdropClassName}`}
    >
      {icon && <div className={`form-element-popup-icon icon-${icons[icon]}`} data-icon={icon} />}
      {props.children}
    </PopUp>
  );
};

export default FormPopUp;
