import { createSlice } from '@reduxjs/toolkit';
import { getLocal, setLocal } from '../../helpers/helpers';

import common_en from '../locales/common_en.json';
import errors_en from '../locales/errors_en.json';
import landing_en from '../locales/landing_en.json';
import refer_en from '../locales/refer_en.json';
import signup_en from '../locales/onboarding/signup_en.json';
import cds_verify_en from '../locales/onboarding/cds_verify_en.json';
import cds_verify_otp_en from '../locales/onboarding/cds_verify_otp_en.json';
import email_verify_en from '../locales/onboarding/email_verify_en.json';
import email_verify_otp_en from '../locales/onboarding/email_verify_otp_en.json';
import mobile_verify_en from '../locales/onboarding/mobile_verify_en.json';
import mobile_verify_otp_en from '../locales/onboarding/mobile_verify_otp_en.json';
import register_en from '../locales/onboarding/register_en.json';
import password_en from '../locales/onboarding/password_en.json';
import pricing_en from '../locales/pricing/pricing_en.json';
import checkout_en from '../locales/pricing/checkout_en.json';
import dashboard_en from '../locales/dashboard_en.json';
import profile_details_en from '../locales/dashboard/profile_details_en.json';
import email_en from '../locales/email_en.json';
import examples_en from '../locales/examples_en.json';
import login_en from '../locales/login_en.json';
import mobile_en from '../locales/mobile_en.json';
import otp_en from '../locales/otp_en.json';
import password_create_en from '../locales/password_create_en.json';
import forget_password_en from '../locales/forget_password_en.json';
import verification_en from '../locales/verification_en.json';
import privacy_policy_en from '../locales/privacy_policy_en.json';
import terms_and_conditions_en from '../locales/terms_and_conditions_en.json';
import help_en from '../locales/help_en.json';

const lang = {
  en: {
    common: common_en,
    errors: errors_en,
    landing: landing_en,
    refer: refer_en,
    signup: signup_en,
    cds_verify: cds_verify_en,
    cds_verify_otp: cds_verify_otp_en,
    email_verify: email_verify_en,
    email_verify_otp: email_verify_otp_en,
    mobile_verify: mobile_verify_en,
    mobile_verify_otp: mobile_verify_otp_en,
    register: register_en,
    password: password_en,
    pricing: pricing_en,
    checkout: checkout_en,
    login: login_en,
    forget_password: forget_password_en,
    dashboard: dashboard_en,
    profile_details: profile_details_en,
    password_create: password_create_en,
    email: email_en,
    otp: otp_en,
    mobileNumber: mobile_en,
    verification: verification_en,
    examples: examples_en,
    privacy_policy: privacy_policy_en,
    terms_and_conditions: terms_and_conditions_en,
    help: help_en,
  },
};

const lang_code = getLocal('asec-web-lang', 'en');

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState: {
    theme: getLocal('asec-web-theme', 'light'),
    timer: {
      count: 0,
      stamp: Date.now(),
    },
    lang: {
      code: lang_code,
      data: lang[lang_code],
      list: Object.keys(lang),
    },
    data: {
      terms_and_conditions: null,
      privacy_policy: null,
    },
    ribbon: {
      open: false,
      mode: 'default',
      description: '',
      button: 'Click Here',
      event: null,
    },
    alert: {
      open: false,
      mode: 'default',
      icon: 'warning',
      title: '',
      description: '',
      actionText: 'Try Again',
      actionEvent: null,
      cancelText: 'Close',
      cancelEvent: null,
    },
  },
  reducers: {
    setTheme(state, action) {
      setLocal('asec-web-theme', action.payload);
      state.theme = action.payload;
    },
    setTimerData(state) {
      state.timer.count += 1;
      state.timer.stamp = Date.now();
    },
    setLang(state, action) {
      setLocal('asec-web-lang', action.payload);
      state.lang.code = action.payload;
      state.lang.data = lang[action.payload];
    },
    setData(state, action) {
      Object.assign(state.data, action.payload);
    },
    setRibbonData(state, action) {
      Object.assign(state.ribbon, action.payload);
    },
    setAlertData(state, action) {
      Object.assign(state.alert, action.payload);
    },
  },
});

export const { setTheme, setTimerData, setLang, setData, setRibbonData, setAlertData } =
  commonSlice.actions;

export default commonSlice.reducer;
