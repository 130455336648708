import { createSlice } from '@reduxjs/toolkit';
import { companyDetailsAction } from './companyDetailsThunk';

const initialState = {
  email: '',
  phone: '',
  phoneSecondary: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  youTube: ``,
  linkedIn: ``,
  facebook: ``,
  instagram: ``,
  twitter: ``,
  companyDetailsData: {
    loading: false,
    data: null,
    error: null,
  },
};

const companyDetailsSlice = createSlice({
  name: 'companyDetailsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(companyDetailsAction.pending, (state = initialState) => {
        state.companyDetailsData.loading = true;
        state.companyDetailsData.error = null;
        state.companyDetailsData.data = null;
      })
      .addCase(companyDetailsAction.fulfilled, (state = initialState, action) => {
        const {
          email,
          phone,
          phoneSecondary,
          addressLine1,
          addressLine2,
          addressLine3,
          youTube,
          linkedIn,
          facebook,
          instagram,
          twitter,
        } = action.payload;
        state.email = email ?? '';
        state.phone = phone ?? '';
        state.phoneSecondary = phoneSecondary ?? '';
        state.addressLine1 = addressLine1 ?? '';
        state.addressLine2 = addressLine2 ?? '';
        state.addressLine3 = addressLine3 ?? '';
        state.youTube = youTube ?? '';
        state.linkedIn = linkedIn ?? '';
        state.facebook = facebook ?? '';
        state.instagram = instagram ?? '';
        state.twitter = twitter ?? '';
        state.companyDetailsData.loading = false;
      })
      .addCase(companyDetailsAction.rejected, (state = initialState, action) => {
        state.companyDetailsData.loading = false;
        state.companyDetailsData.error = action.payload;
      });
  },
});

export default companyDetailsSlice.reducer;
