import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTVChartProperties } from "../../api/lib/tv-chart-property.api";

export const getTVChartAction = createAsyncThunk(
    'tvChartSlice/getTVChartAction',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getTVChartProperties(payload);

            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
)