import { apiClient } from "../apiClient";

/**
 * This function retrieves TV chart properties by making a GET request to a specific API endpoint.
 * @returns The function `getTVChartProperties` is returning the result of the API call made to
 * `/api/v1/subscription/chart/config` using the `apiClient.get` method. This result is being returned
 * as a promise due to the `async` keyword used in the function declaration.
 */
export const getTVChartProperties = async () => {
    try {
        return await apiClient.get(`/api/v1/subscription/chart/config`);
    } catch (error) {
        throw error;
    }
}