import { createSlice } from "@reduxjs/toolkit";
import { getTermsAndConditionsAction } from "./termsAndConditionThunk";

const initialState = {
    data: {
        termsAndConditions: {}
    },

    termsAndConditionsData: {
        data: null,
        loading: false,
        error: null
    }
};

const termsAndConditionSlice = createSlice({
    name: 'termsAndConditionSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //  GET TermsAndConditionsData Action
            .addCase(getTermsAndConditionsAction.pending, (state = initialState) => {
                state.termsAndConditionsData.loading = true;
                state.termsAndConditionsData.error = null;
                state.termsAndConditionsData.data = null;
            })

            .addCase(getTermsAndConditionsAction.fulfilled, (state = initialState, action) => {
                state.termsAndConditionsData.loading = false;
                state.termsAndConditionsData.error = null;
                state.termsAndConditionsData.data = action.payload;
                state.data.termsAndConditions = action.payload?.tNC;
            })

            .addCase(getTermsAndConditionsAction.rejected, (state = initialState, action) => {
                state.termsAndConditionsData.loading = false;
                state.termsAndConditionsData.error = action.payload;
            })
    },
});

export default termsAndConditionSlice.reducer;