import { createAsyncThunk } from '@reduxjs/toolkit';
import { postCreateUser } from '../../api/lib/auth.api';
import {
  getUsernameAvailability,
  postSendOTP,
  postValidateCDSAccount,
  postValidateNIC,
  postVerifyOTP,
} from '../../api/lib/registeration.api';
import { postValidateOTPForCDS } from '../../api/lib/otp.api';

export const validateNICAction = createAsyncThunk(
  'registerSlice/validateNICAction',
  async (payload = { idType: 'NIC', idNumber: '' }, { rejectWithValue }) => {
    try {
      const response = await postValidateNIC(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const cdsAccountValidationAction = createAsyncThunk(
  'registerSlice/cdsAccountValidationAction',
  async (payload = { cdsAccountNumber: '' }, { rejectWithValue }) => {
    try {
      const response = await postValidateCDSAccount(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const validateOTPForCDSAction = createAsyncThunk(
  'registerSlice/validateOTPForCDS',
  async (payload = { otp: '' }, { rejectWithValue }) => {
    try {
      const response = await postValidateOTPForCDS(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const verifyEmailAction = createAsyncThunk(
  'registerSlice/verifyEmailAction',
  async (payload = { type: 'EMAIL', value: '' }, { rejectWithValue }) => {
    try {
      const response = await postSendOTP(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const verifyEmailOTPAction = createAsyncThunk(
  'registerSlice/verifyEmailOTPAction',
  async (payload = { type: 'EMAIL', otp: '' }, { rejectWithValue }) => {
    try {
      const response = await postVerifyOTP(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const verifyMobileAction = createAsyncThunk(
  'registerSlice/verifyMobileAction',
  async (payload = { type: 'PHONE', value: '' }, { rejectWithValue }) => {
    try {
      const response = await postSendOTP(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const verifyMobileOTPAction = createAsyncThunk(
  'registerSlice/verifyMobileOTPAction',
  async (payload = { type: 'PHONE', otp: '' }, { rejectWithValue }) => {
    try {
      const response = await postVerifyOTP(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createUserAction = createAsyncThunk(
  'registerSlice/createUserAction',
  async (payload = {}, { rejectWithValue }) => {
    try {
      const response = await postCreateUser(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const verifyUsernameAvailabilityAction = createAsyncThunk(
  'registerSlice/verifyUsernameAvailabilityAction',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getUsernameAvailability(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);