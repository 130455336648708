import { apiClient } from '../apiClient';
import { getSession } from '../../helpers/helpers';

export const postValidateOTPForCDS = async (payload) => {
  try {
    return await apiClient.post(
      '/api/v1/users/validate/otp',
      {
        otp: payload.otp,
      },
      {
        headers: {
          'user-id': getSession('userId'),
        },
      },
    );
  } catch (error) {
    throw error;
  }
};
