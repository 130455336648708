import { useState, useEffect } from 'react';
import './PopUp.css';

// default props for popup component
const OuterProps = {
  // popup opened state
  open: false,
  // render level
  level: 0,
  // container class name
  className: 'react-popup-container',
  // backdrop class name
  backdropClassName: 'react-popup-backdrop',
  // input referer
  referer: null,
  // backdrop click event
  onBackdrop: () => false,
};

// outer element
const PopUp = (props = OuterProps) => {
  // open state
  const [open, setOpen] = useState(props.open);
  // mounted state
  const [mounted, setMounted] = useState(false);
  // set mounted state after delay
  useEffect(() => {
    setTimeout(() => setMounted(true), 200);
  }, []);
  // popup level
  const level = 10000 + parseInt(props.level || OuterProps.level);
  // popup styles
  const style = { zIndex: level };
  // popup open state
  const state = props.open ? 'opened' : mounted ? 'closed' : 'hidden';
  // get backdrop extra class name
  const classDropExtra = props.backdropClassName ? ' ' + props.backdropClassName : '';
  // get backdrop class name
  const classDrop = 'react-popup-backdrop' + classDropExtra;
  // get container extra class name
  const classContExtra = props.className ? ' ' + props.className : '';
  // get container class name
  const classCont = 'react-popup-container' + classContExtra;
  // update open state
  if (open !== props.open) {
    setOpen(props.open);
  }
  // effect on open
  useEffect(() => {
    // get document element
    const element = document.documentElement;
    // switch element overflow style
    open ? (element.style.overflow = 'hidden') : (element.style = '');
  }, [open]);
  // return outer dom
  return (
    <div className='react-popup-outer' style={style} data-state={state}>
      <div className={classDrop} onClick={props.onBackdrop} />
      <div id={props.id} className={classCont} ref={props.referer}>
        {props.children}
      </div>
    </div>
  );
};

export default PopUp;
