/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.css';
import { widget } from '../../charting_library';
import { getSession } from '../../helpers/helpers';
import { getNewsAction } from '../../store/news/newsThunk';

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function getParameterByName(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

var datafeedUrl = 'https://asec.dev.arimac.digital/atrad-svc/api';
// var datafeedUrl = "https://asec.dev.arimac.digital/user-mgt-svc/api/v1/users";
// var datafeedUrl = "https://demo-feed-data.tradingview.com";
var customDataUrl = getParameterByName('dataUrl');
if (customDataUrl !== '') {
  datafeedUrl = customDataUrl.startsWith('https://') ? customDataUrl : `https://${customDataUrl}`;
}

const token = getSession('auth_token');

const defaultProps = {
  symbol: 'AAF.N0000',
  interval: 'M',
  datafeedUrl: datafeedUrl,
  libraryPath: '/charting_library/',
  chartsStorageUrl: 'https://saveload.tradingview.com',
  chartsStorageApiVersion: '1.1',
  clientId: 'tradingview.com',
  userId: 'public_user_id',
  fullscreen: false,
  autosize: true,
  studiesOverrides: {},
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
  disabled_features: [
    'use_localstorage_for_settings',
    'context_menus',
    'header_settings',
    'header_indicators',
    'timezone_menu',
    'show_object_tree',
    'symbol_info',
    'header_saveload',
    'header_screenshot',
    'trading_account_manager',
  ],
  enabled_features: [],
};

export const TVChartContainer = (props) => {
  const chartContainerRef = useRef();
  const newsState = useSelector((state) => state.newsSlice.news.data);
  const dispatch = useDispatch();
  const authToken = getSession('auth_token');
  const [currentSymbol, setCurrentSymbol] = useState('');
  const [marketStatus, setMarketStatus] = useState(null);

  useEffect(() => {
    console.log('Dispatching getNewsAction');
    dispatch(getNewsAction(props.symbol));
    console.log('Dispatching getNewsAction', newsState);
  }, [dispatch, props.symbol]);

  const staticNewsData = [
    {
      id: '1', // Ensure each news item has a unique ID
      title: 'Static News Item 1',
      published: '2024-05-22 09:08:42', // Use ISO date format
      source: 'Example Source',
      shortDescription: 'This is a static news item for testing.',
      link: 'https://example.com/news1',
      fullDescription: 'This is the full description of static news item 1.',
    },
    {
      id: '2',
      title: 'Static News Item 2',
      published: '2024-05-22 09:08:42', // 24 hours ago
      source: 'Example Source',
      shortDescription: 'This is another static news item for testing.',
      link: 'https://example.com/news2',
      fullDescription: 'This is the full description of static news item 2.',
    },
  ];

  const newsProvider = (symbol, callback) => {
    console.log('newsProvider called with symbol:', symbol);
    callback({
      title: symbol, // Optional title
      newsItems: staticNewsData,
    });
  };

  useEffect(() => {
    const extraFeature = 'dom_widget';
    const widgetOptions = {
      symbol: props.symbol,
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(props.datafeedUrl, props.headers),
      interval: props.interval,
      container: chartContainerRef.current,
      library_path: '/charting_library/',
      locale: getLanguageFromURL() || 'en',
      disabled_features: props.disabled_features,
      // enabled_features: props.enabled_features,
      enabled_features: [
        'header_saveload',
        'header_compare',
        'study_templates',
        'confirm_overwrite_if_chart_layout_with_name_exists',
        'seconds_resolution',
        'dom_widget',
        'disable_resolution_rebuild',
        'header_settings',
        'display_market_status',
      ],
      charts_storage_url: props.chartsStorageUrl,
      charts_storage_api_version: props.chartsStorageApiVersion,
      client_id: props.clientId,
      user_id: props.userId,
      fullscreen: props.fullscreen,
      autosize: props.autosize,

      studies_overrides: props.studiesOverrides,
      settings_adapter: {
        initialSettings: {
          // symbolWatermark: '{ "visibility": "true", "color": "rgba(244, 67, 54, 0.25)" }',
          // 'trading.Broker':
          //   '{"qty": {"AAPL": 10, "NasdaqNM:AAPL": 10}, "orderType": {"NasdaqNM:AAPL": 3}}',
        },
        setValue: function (key, value) {
          console.log(`Set value: ${key} ${value}`);
        },
        removeValue: function (key) {
          console.log(`Remove value: ${key}`);
        },
      },
      widgetbar: props.widgetbar,
      supported_resolutions: ['5', '15', '60', '240', 'D', '3D', '1W', '1M', '3M', '6M'],
      has_seconds: true,
      has_intraday: true,
      debug: true,
      rss_news_feed: {
        default: [
          {
            url: `https://asec.dev.arimac.digital/atrad-svc/api/news/rss?symbol=${currentSymbol}`,
            name: 'Market news',
          },
        ],
      },
      drawings_access: props.drawings_access,
      studies_access: props.studies_access,
      theme: props.theme,
      broker_factory: function (host) {
        return new window.Brokers.BrokerSample(host, widgetOptions.datafeed);
      },
      broker_config: {
        configFlags: {
          supportNativeReversePosition: true,
          supportPlaceOrderPreview: true,
          supportClosePosition: true,
          supportPLUpdate: true,
          supportLevel2Data: true,
          showQuantityInsteadOfAmount: true,
          supportEditAmount: true,
          supportOrderBrackets: true,
          supportMarketBrackets: true,
          supportPositionBrackets: true,
          supportPositions: true,
          supportDOM: true,
        },
        durations: [
          { name: 'DAY', value: 'DAY' },
          { name: 'GTC', value: 'GTC' },
          // { name: 'GTD', value: 'GTD' }, advised by @Shamindri Perera to not display this option as implementation has not been done.
        ],
      },
    };

    const tvWidget = new widget(widgetOptions);

    // tvWidget.onChartReady(() => {
    //   tvWidget.activeChart().resolution();
    //   // Get the current symbol
    //   const symbol = tvWidget.chart().symbol();
    //   setCurrentSymbol(symbol);
    //   console.log('Calling onready', symbol);

    //   tvWidget
    //     .activeChart()
    //     .onSymbolChanged()
    //     .subscribe(null, () => {
    //       // let symbol = tvWidget.chart().symbol();
    //       console.log('The symbol is changed', symbol);
    //       console.log('The symbol is changed', tvWidget.symbol);
    //       setCurrentSymbol(symbol);
    //     });
    //   tvWidget.onSymbolChanged((symbol1) => {
    //     console.log('The symbol is changed', symbol1);
    //   });
    // });

    tvWidget.onChartReady(() => {
      // console.log(' tvWidget.activeChart().resolution();', tvWidget.activeChart().symbol());
      // setCurrentSymbol(tvWidget.activeChart().symbol());
      // tvWidget.activeChart().onSymbolChanged((sym) => {
      //   console.log(' tvWidget.activeChart().resolution();3333333', sym.name);
      //   console.log(' tvWidget.activeChart().resolution();eeeeeeeeeeeeeeeee', sym);
      //   setCurrentSymbol(tvWidget.activeChart().symbol());
      // });
      // tvWidget
      //   .chart()
      //   .onSymbolChanged()
      //   .subscribe((sym) => {
      //     console.log(
      //       ' tvWidget.activeChart().resolution();3333333',
      //       tvWidget.activeChart().symbol(),
      //     );
      //     console.log(' tvWidget.activeChart().resolution();eeeeeeeeeeeeeeeee', sym.name);
      //     setCurrentSymbol(sym.name);
      //   });
      // const marketStatusSubscription = tvWidget
      //   .activeChart()
      //   .marketStatus()
      //   .subscribe((status) => {
      //     console.log('Market status changed:', status);
      //     localStorage.setItem('market_status', JSON.stringify(status));
      //     setMarketStatus(status);
      //     console.log('skdmfdkmfksndf MARKET', localStorage.getItem('market_status'));
      //     console.log('skdmfdkmfksndf MARKET', marketStatus?.name);
      //   });
      // tvWidget.activeChart().marketStatus((status) => {
      //   console.log('Market status changed:', status);
      //   localStorage.setItem('market_status', JSON.stringify(status));
      //   setMarketStatus(status);
      //   console.log('skdmfdkmfksndf MARKET', localStorage.getItem('market_status'));
      //   console.log('skdmfdkmfksndf MARKET', marketStatus?.name);
      // });
      // tvWidget
      //   .activeChart()
      //   .onSymbolChanged()
      //   .subscribe(null, (symbolInfo) => {
      //     console.log('The symbol has changed to:', symbolInfo.name);
      //     setCurrentSymbol(symbolInfo.name);
      //   });
      // tvWidget
      //   .activeChart()
      //   .onChartTypeChanged()
      //   .subscribe(null, (chartType) => {
      //     console.log('Chart type changed:', chartType);
      //   });
      // tvWidget
      //   .activeChart()
      //   .onDataLoaded()
      //   .subscribe(null, () => {
      //     console.log('New history bars are loaded');
      //   });
      // tvWidget
      //   .activeChart()
      //   .onHoveredSourceChanged()
      //   .subscribe(null, (sourceId) => {
      //     console.log('Hovered source ID:', sourceId);
      //   });
      // tvWidget
      //   .activeChart()
      //   .onIntervalChanged()
      //   .subscribe(null, (interval, timeframeObj) => {
      //     console.log('Interval changed:', interval, 'Timeframe object:', timeframeObj);
      //   });
      // return () => {
      //   marketStatusSubscription.unsubscribe();
      // };
      // tvWidget.chart().onSymbolChanged(() => {
      //   console.log(
      //     ' tvWidget.activeChart().resolution();3333333',
      //     tvWidget.activeChart().symbol(),
      //   );
      //   setCurrentSymbol(tvWidget.activeChart().symbol());
      // });
      // tvWidget.headerReady().then(() => {
      //   const button = tvWidget.createButton();
      //   button.setAttribute('title', 'Click to show a notification popup');
      //   button.classList.add('apply-common-tooltip');
      //   button.addEventListener('click', () =>
      //     tvWidget.showNoticeDialog({
      //       title: 'Notification',
      //       body: 'TradingView Charting Library API works correctly',
      //       callback: () => {
      //         console.log('Noticed!');
      //       },
      //     }),
      //   );
      //   button.innerHTML = 'Check API';
      // });
    });

    return () => {
      tvWidget.remove();
    };
  });

  return <div ref={chartContainerRef} className={'TVChartContainer'} />;
};
