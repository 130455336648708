import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DropdownMenu = ({ onLogout, lang }) => {
  const [dropdown, setDropdown] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div
        className='dashboard-layout-profile-arrow icon-arrow-down-2'
        onClick={() => setDropdown(true)}
      />
      {dropdown && (
        <>
          <div className='dashboard-layout-dropdown'>
            <div className='dashboard-layout-dropdown-list'>
              <div
                className='dashboard-layout-dropdown-item typo-body-v3 logout'
                onClick={() => {
                  onLogout();
                  setDropdown(false);
                }}
              >
                {lang.layout.dropdown.logout}
              </div>
              <div
                className='dashboard-layout-dropdown-item typo-body-v3'
                onClick={() => {
                  navigate('/dashboard');
                  setDropdown(false);
                }}
              >
                Dashboard
              </div>
              <div
                className='dashboard-layout-dropdown-item typo-body-v3'
                onClick={() => {
                  navigate('/pricing');
                  setDropdown(false);
                }}
              >
                Pricing
              </div>
            </div>
          </div>
          <div className='dashboard-layout-dropdown-backdrop' onClick={() => setDropdown(false)} />
        </>
      )}
    </>
  );
};

export default DropdownMenu;
