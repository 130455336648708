import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { store, asecPersistStore } from './store/store';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={asecPersistStore} loading={<>loading...</>}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
);

reportWebVitals();
